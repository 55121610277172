<template>
	<div style="overflow: auto;" id="content-to-print">
		<TitleWithBackButton title="Fasce Orarie" back-route="Analytics"></TitleWithBackButton>
		<div 
			class="d-flex align-center ml-3 mt-3"
			:class="{'flex-wrap': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
          || this.$vuetify.breakpoint.md}"
		>
      <RangeDateSelector 
        ref="dateSelector"
				:start-date.sync="startDate" 
				:end-date.sync="endDate" 
				:loading="loading" 
				:disabled="loading"
				:max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'"
			>
			</RangeDateSelector>
			<div 
				class="ml-3"
				:class="{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
          || this.$vuetify.breakpoint.md}"
				:style="{
					'width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 
						'50dvw' : '20dvw'
				}"
			>
				<v-select
					v-model="selectedDays"
					:items="days"
					label="Giorni"
					item-text="name"
					item-value="key"
					class="pt-6"
					multiple
					rounded
					filled
					dense
				>
					<template v-slot:selection="{ item, index }">
						<span v-if="index === 0">
							{{ item['name'] }}
						</span>
						<span
							v-else-if="index < 3"
						>
							, {{ item['name'] }}
						</span>
						<span
							v-else-if="index === 3"
							class="grey--text text-caption"
						>
							&nbsp; (e altri {{ selectedDays.length - 3 }})
						</span>
					</template>
				</v-select>
			</div>
      <div 
				class="ml-3"
				:style="{
					'width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 
						'36dvw' : '20dvw'
				}"
			>
        <TagsCategoriesAutocomplete 
          label="Scelta Tag" 
          v-model="selectedTagCategories" 
          :return-object="false"
          :prepend-icon="null" 
					:filters="{ typeCost: false, excludeInBalance: false, notGeneral: true }"
        >
        </TagsCategoriesAutocomplete>
      </div>
			<v-spacer></v-spacer>
			<v-btn class="mr-2 ml-2" text @click="calculate" :loading="loading">
				Calcola
			</v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
			<v-btn class="mr-2 ml-2" text @click="print" :loading="loading">
				<v-icon> mdi-printer </v-icon></v-btn>
			<v-btn icon large :disabled="loading" style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!ghost ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
		</div>
		<div class="d-flex justify-center mt-10">
			<v-col cols="12" class="px-10">
				<v-row class="d-flex flex-column">
					<div>
						<v-btn-toggle
							v-model="dayOrHour"
							mandatory
							rounded
							dense
							borderless
							color="primary"
						>
							<v-tooltip bottom>
      					<template v-slot:activator="{ on, attrs }">
									<v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
										<v-icon>mdi-calendar-outline</v-icon>
									</v-btn>
								</template>
								<span>Giorni</span>
							</v-tooltip>
							<v-tooltip bottom>
      					<template v-slot:activator="{ on, attrs }">
									<v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
										<v-icon>mdi-clock-outline</v-icon>
									</v-btn>
								</template>
								<span>Ore</span>
							</v-tooltip>
						</v-btn-toggle>
					</div>
					<v-window
						v-model="dayOrHour"
					>
						<v-window-item>
							<v-row>
								<v-col cols="12" class="d-flex justify-center mb-5">
									<v-chip 
										class="text-button text-center" 
										style="font-size: 1.2rem !important;"
										color="primary"
									>
										Giorni
									</v-chip>
								</v-col>
							</v-row>
							<GroupBasedChart 
								height="400px" 
								width="100%"
								transparent
								type="bar"
								:colors="barColors"
								:point-colors="[]"
								:getYValue="
									(object) => {
										return object[`y`];
									}
								"
								:getXValue="
									(object) => {
										return object[`x`];
									}
								"
								:tooltip-label-modifier="
									(tooltipLabel) => {
										return Number(tooltipLabel.yLabel).toFixed(2);
									}
								"
								:data="[dayOfWeekChartData]"
							>
							</GroupBasedChart>
						</v-window-item>
						<v-window-item>
							<v-row>
								<v-col cols="12" class="d-flex justify-center mb-5">
									<v-chip 
										class="text-button text-center" 
										style="font-size: 1.2rem !important;"
										color="primary"
									>
										Ore
									</v-chip>
								</v-col>
							</v-row>
							<GroupBasedChart 
								height="400px" 
								width="100%"
								transparent
								type="bar"
								:colors="barColors"
								:point-colors="[]"
								:getYValue="
									(object) => {
										return object[`y`];
									}
								"
								:getXValue="
									(object) => {
										return object[`x`];
									}
								"
								:tooltip-label-modifier="
									(tooltipLabel) => {
										return Number(tooltipLabel.yLabel).toFixed(2);
									}
								"
								:data="[hourOfDayChartData]"
							>
							</GroupBasedChart>
						</v-window-item>
					</v-window>
				</v-row>
				<v-row class="d-flex justify-center pt-10 mb-2">
					<v-chip 
						class="text-button text-center" 
						style="font-size: 1.2rem !important;"
						color="primary"
					>
						Fatturato
					</v-chip>
				</v-row>
				<v-row class="pb-5">
					<TypeDataTable
						class="balance-table"
						headersColor="rgba(138, 119, 226)" 
						fixed-header
						style="background-color: rgba(138, 119, 226,0.2); width: 100%; position: sticky; z-index: 99"
						:headers="[
							{ text: '', value: 'name' },
							...daysColsTotal,
							{ text: 'Totale', value: 'total', type: 'price'}
						]" 
						:items="dayAndHourTotalData" 
						:show-select="false" 
						:select-on-row-click="false"
						:show-actions="false" 
						:disablePagination="true" 
						dense 
						disable-sort
					>
					</TypeDataTable>
				</v-row>
				<v-row class="d-flex justify-center mb-2 pt-2">
					<v-chip 
						class="text-button text-center" 
						style="font-size: 1.2rem !important;"
						color="primary"
					>
						Transazioni
					</v-chip>
				</v-row>
				<v-row class="pb-5">
					<TypeDataTable
						class="balance-table"
						headersColor="rgba(138, 119, 226)" 
						style="background-color: rgba(138, 119, 226,0.2); width: 100%; position: sticky; z-index: 99"
						:headers="[
							{ text: '', value: 'name' },
							...daysColsCount,
							{ text: 'Totale', value: 'total' }
						]" 
						:items="dayAndHourCountData" 
						:show-select="false" 
						:select-on-row-click="false"
						:show-actions="false" 
						:disablePagination="true" 
						dense 
						disable-sort
					>
					</TypeDataTable>
				</v-row>
				<v-divider class="mt-10"></v-divider>
				<v-row class="d-flex justify-center mt-5">
					<v-col 
						lg="3"
						xl="3"
						md="6" 
						cols="6"
						sm="6"   
						v-for="day in selectedDays" 
						:key="'day_' + day"
					>
						<v-row class="d-flex justify-center mb-2 mt-2">
							<v-chip 
								class="text-button text-center" 
								style="font-size: 1.2rem !important;"
								color="primary"
							>
								{{ dayMapped[day] }}
							</v-chip>
						</v-row>
						<PolarAreaChart
							:chart-data="daysChartData[day]"
							:options="daysChartOptions"
						></PolarAreaChart>
						<TypeDataTable
							class="balance-table mt-5"
							headersColor="rgba(138, 119, 226)" 
							fixed-header
							style="background-color: rgba(138, 119, 226,0.2); width: 100%; position: sticky; z-index: 99"
							item-key="key"
							:headers="[
								{ text: '', value: 'name' },
								{ text: '€', value: 'total', type: 'price' },
								{ text: '% sulla Sett.', value: 'onTotal' },
								{ text: '% sul Giorno', value: 'onDay' }
							]" 
							:items="daysData[day]" 
							:show-select="false" 
							:select-on-row-click="false"
							:show-actions="false" 
							:disablePagination="true" 
							disable-sort
							show-expand
						>
							<template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length" class="pa-0 pl-5">
                  <TypeDataTable
                    class="balance-table"
                    headersColor="rgb(190,190,190)" 
                    style="background-color: rgba(50,50,50,0.1); position: sticky; z-index: 1"
                    item-key="name"
                    :headers="[
											{ text: '', value: 'name' },
											{ text: '€', value: 'total', type: 'price' },
											{ text: '% sul Giorno', value: 'onDay' }
                    ]" 
                    :items="item.subItems" 
                    :show-select="false" 
                    :select-on-row-click="false"
                    :show-actions="false" 
                    :disablePagination="true" 
                    dense 
                    disable-sort
										show-expand
                  >
										<template v-slot:expanded-item="{headers, item}">
											<td :colspan="headers.length" class="pa-0 pl-5">
												<TypeDataTable
													class="balance-table"
													headersColor="rgb(190,190,190)" 
													style="background-color: rgba(50,50,50,0.1); position: sticky; z-index: 1"
													item-key="name"
													:headers="[
														{ text: '', value: 'name' },
														{ text: 'N° Transazioni', value: 'count' },
														{ text: '€', value: 'total', type: 'price' },
														{ text: '% sul Giorno', value: 'onDay' }
													]" 
													:items="item.subItems" 
													:show-select="false" 
													:select-on-row-click="false"
													:show-actions="false" 
													:disablePagination="true" 
													dense 
													disable-sort
												>
												</TypeDataTable>
											</td>
										</template>
                  </TypeDataTable>
                </td>
              </template>
						</TypeDataTable>
					</v-col>
				</v-row>
			</v-col>
		</div>

		<StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
	</div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import PolarAreaChart from "@/components/charts/PolarAreaChart.vue";
import balanceService from '@/services/balance/balance.service.js';
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import operatorService from '@/services/operators/operators.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import _ from 'lodash'
import * as XLSX from "xlsx";

export default {
	name: "TimeRangeProduction",
	components: {
		DateTimePicker,
		TitleWithBackButton,
		GroupBasedChart,
		TypeDataTable,
		StandardDialog,
		ManualBarcodeInput,
		TagsCategoriesAutocomplete,
		PolarAreaChart,
		RangeDateSelector
	},
	data: () => {
		return {
			startDate: undefined,
			endDate: undefined,
			ghost: false,
			dialogBarcode: false,
			operatorBarcode: undefined,
			loading: false,
			dayOrHour: 0,
			selectedTagCategories: undefined,
			dayMapped: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
			days: [{name:'Domenica', key: 0}, {name:'Lunedì', key: 1}, {name:'Martedì', key: 2}, {name:'Mercoledì', key: 3}, {name:'Giovedì', key: 4}, {name:'Venerdì', key: 5}, {name:'Sabato', key: 6}],
			selectedDays: [2, 3, 4, 5, 6],
			daysColsTotal: [
			],
			daysColsCount: [
			],
			hourMapped: ['0:00 - 4:00', '4:00 - 8:00', '8:00 - 12:00', '12:00 - 16:00', '16:00 - 20:00', '20:00 - 24:00'],
			barColors: ['#FF63849c', '#36A2EB9c', '#FFCE569c', '#4BC0C09c', '#9966FF9c', '#FF9F409c', '#FFFFFF9c'],
			dayOfWeekChartData: {
        name: 'Incassi sui Giorni',
        label: 'Sui Giorni',
        data: [],
      },
			hourOfDayChartData: {
        name: "Incassi sull'Ora",
        label: "Sull'Ora",
        data: [],
      },
			dayAndHourTotalData: [],
			dayAndHourCountData: [],
			typeMapped: {
				service: 'Servizi',
				item: 'Prodotti',
			},
			daysData: {},
			daysChartData: {
				0: {},
				1: {},
				2: {},
				3: {},
				4: {},
				5: {},
				6: {}
			},
			daysChartOptions: {
        scale: {
          pointLabels: {
            display: false,
            centerPointLabels: true,
            fontSize: 14,
            centerPointLabels: true,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (val) => {
              let total = Number(val.value)
							return total.toFixed(2) + '€'
            },
          },
        }
      },
      fileName: ""
		}
	},
	mounted: async function () {
    await this.$refs.dateSelector.setThisMonth()

		let tagsCategories = await tagsCategoriesService.list(1, 4000, { typeCost: false, excludeInBalance: false, notGeneral: true })
		this.selectedTagCategories = tagsCategories.rows.map(tc => tc.id)
		this.calculate()
	},
	methods: {
    exportToExcel() {
      let workbook = XLSX.utils.book_new();

      //Creazione tabella fatturato
      let ws = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws, [
				[
          '',
          ...this.selectedDays.map((e) => {
            return this.days.find((d) => d.key == e).name
          }),
          'Totale'
        ],
				...this.dayAndHourTotalData.map((el) => {
					return [
						el.name,
						...this.selectedDays.map((e) => {
							return this.days.find((d) => d.key == e).key
						}).map((key) => !!el[key] ? el[key] + " €" : "0 €"),
						el.total+ " €"
					]
				}),
        [],
        [
          '',
          ...this.selectedDays.map((e) => {
            return this.days.find((d) => d.key == e).name
          }),
          'Totale'
        ],
				...this.dayAndHourCountData.map((el) => {
					return [
						el.name,
						...this.selectedDays.map((e) => {
							return this.days.find((d) => d.key == e).key
						}).map((key) => el[key] || "0"),
						Number(el.total)
					]
				})
      ]);
      XLSX.utils.book_append_sheet(workbook, ws, "Fatturato e Transazioni");
      //Creazione tabella transazioni
      
      //Creazione array giorni selezionati con Nome e Key
      let daysWorksheetsNamesArray = []
      for (let i = 0; i < this.selectedDays.length; i++) {
        for (let j = 0; j < this.days.length; j++) {
          //Facciamo un controllo sui giorni selezionati dall'array dei giorni totali
          if(this.selectedDays[i] === this.days[j].key)
            daysWorksheetsNamesArray.push(this.days[j])
        }
      }
			
      let daysArray = [],
      daysCompleteArray = []
      for (let i = 0; i < Object.keys(this.daysData).length; i++) {
        let keyDay = Object.keys(this.daysData)[i]
        for (let k = 0; k < this.daysData[keyDay].length; k++) {
          let setDayKey = this.daysData[keyDay][k]
          setDayKey.dayKey = keyDay
          if(setDayKey.name.includes('Tot'))
            setDayKey.day = "Totale"
          daysArray.push(this.daysData[keyDay][k])
        }
      }

      //Aggiunta del campo dayKey anche per i subItems per poterli filtrare 
      //al momento dell'inserimento nei vari fogli Excel
      let firstLevelArray = [],
      secondLevelArray = [],
      thirdLevelArray = []
      for (let i = 0; i < daysArray.length; i++) {
        let selectedDayKey = daysArray[i].dayKey
        let selectedDay = daysArray[i]
        daysCompleteArray.push(daysArray[i])
        firstLevelArray.push(daysArray[i])
        if(daysArray[i].subItems.length > 0)
          for (let j = 0; j < daysArray[i].subItems.length; j++) {
            daysArray[i].subItems[j].dayKey = selectedDayKey
            daysCompleteArray.push(daysArray[i].subItems[j])
            if (!(!!selectedDay.day))
              secondLevelArray.push(daysArray[i].subItems[j])
            if(daysArray[i].subItems[j].subItems.length > 0)
              for (let k = 0; k < daysArray[i].subItems[j].subItems.length; k++) {
                daysArray[i].subItems[j].subItems[k].dayKey = selectedDayKey
                daysArray[i].subItems[j].subItems[k].category = daysArray[i].subItems[j].name
                daysCompleteArray.push(daysArray[i].subItems[j].subItems[k])
                if (!(!!selectedDay.day))
                  thirdLevelArray.push(daysArray[i].subItems[j].subItems[k])
              }
          }
      }

      //Crea una pagina Excel per ogni giorno della settimana
      for (let i = 0; i < daysWorksheetsNamesArray.length; i++) {
        //il WS attuale non va bene, bisogna inserire i dati dei diagrammi a torta sottostanti a Transazioni
        let dayData = [],
        lev1 = [],
        lev2 = [],
        lev3Complete = [],
        lev3Services = [],
        lev3Products = []
        for (let j = 0; j < daysCompleteArray.length; j++) {
          if(daysCompleteArray[j].dayKey === daysWorksheetsNamesArray[i].key.toString())
            dayData.push(daysCompleteArray[j])
        }
        for (let j = 0; j < firstLevelArray.length; j++) {
          if(firstLevelArray[j].dayKey === daysWorksheetsNamesArray[i].key.toString())
            lev1.push(firstLevelArray[j])
        }
        for (let j = 0; j < secondLevelArray.length; j++) {
          if(secondLevelArray[j].dayKey === daysWorksheetsNamesArray[i].key.toString())
            lev2.push(secondLevelArray[j])
        }
        for (let j = 0; j < thirdLevelArray.length; j++) {
          if(thirdLevelArray[j].dayKey === daysWorksheetsNamesArray[i].key.toString()) {
            lev3Complete.push(thirdLevelArray[j])
            if(thirdLevelArray[j].category === "Servizi")
              lev3Services.push(thirdLevelArray[j])
            else if(thirdLevelArray[j].category === "Prodotti")
              lev3Products.push(thirdLevelArray[j])
          }
        }
        let ws2 = XLSX.utils.aoa_to_sheet([[]]);
        XLSX.utils.sheet_add_aoa(ws2, [
          [
            "",
            "€",
            "% sulla settimana",
            "% sul giorno"
          ],
          ...lev1.map((el) => {
            return [
              el.name,
              el.total,
              el.onTotal,
              el.onDay
            ]
          }),
          [],
          [
            "",
            "€",
            "% sul giorno"
          ],
          ...lev2.map((el) => {
            return [
              el.name,
              el.total,
              el.onDay
            ]
          }),
          [],
          [
            "Servizio",
            "€",
            "% sul giorno"
          ],
          ...lev3Services.map((el) => {
            return [
              el.name,
              el.total,
              el.onDay
            ]
          }),
          [],
          [
            "Prodotto",
            "€",
            "% sul giorno"
          ],
          ...lev3Products.map((el) => {
            return [
              el.name,
              el.total,
              el.onDay
            ]
          }),
        ]);
        XLSX.utils.book_append_sheet(workbook, ws2, daysWorksheetsNamesArray[i].name);
      }
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
		async calculate() {
			this.loading = true

			const startWeek = new Date(this.startDate)
			startWeek.setHours(0)
			startWeek.setMinutes(0)
			startWeek.setSeconds(0)
			startWeek.setMilliseconds(0)

			const endWeek = new Date(this.endDate)
			endWeek.setHours(23)
			endWeek.setMinutes(59)
			endWeek.setSeconds(59)
			endWeek.setMilliseconds(999)
      this.fileName = "Fasce_Orarie_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";

			let res = await balanceService.getTimeRange({
				from: startWeek,
				to: endWeek,
				ghost: this.ghost,
				days: this.selectedDays,
				selectedTagCategories: this.selectedTagCategories
			})

			this.daysColsTotal = this.selectedDays.sort((a,b) => (a || 7) - (b || 7)).map(day => {
				let dayName = this.days.find(d => d.key === day).name
				return {
					text: dayName,
					value: day,
					type: 'price'
				}
			})
			this.daysColsCount = this.selectedDays.sort((a,b) => (a || 7) - (b || 7)).map(day => {
				let dayName = this.days.find(d => d.key === day).name
				return {
					text: dayName,
					value: day,
					type: 'number'
				}
			})

			res.dayOfWeek.sort((a, b) => {
				let dayA = Number(a.dayOfWeek) || 7
				let dayB = Number(b.dayOfWeek) || 7
				return dayA - dayB
			})
			this.dayOfWeekChartData.data = res.dayOfWeek.map(dow => {
				return {
					x: this.dayMapped[dow.dayOfWeek],
					y: Number(dow.total)
				}
			})

			this.hourOfDayChartData.data = res.hourOfDay.map(hod => {
				return {
					x: this.hourMapped[hod.hourOfDay],
					y: Number(hod.total)
				}
			})



			let groupedByHour = _.groupBy(res.dayAndHour, 'hourOfDay')
			this.dayAndHourTotalData = []
			this.dayAndHourCountData = []

			for(let hour of Object.keys(groupedByHour)){
				let days = groupedByHour[hour]

				let objTotal = {
					name: this.hourMapped[hour],
				}
				let objCount = {
					name: this.hourMapped[hour],
				}

				for(let day of days){
					objTotal[day.dayOfWeek] = day.total
					objCount[day.dayOfWeek] = day.count
				}
				objTotal.total = res.hourOfDay.find(hod => Number(hod.hourOfDay) === Number(hour)).total
				objCount.total = res.hourOfDay.find(hod => Number(hod.hourOfDay) === Number(hour)).count

				this.dayAndHourTotalData.push(objTotal)
				this.dayAndHourCountData.push(objCount)
			}

			let objTotal = {
				name: 'Totale',
			}
			let objCount = {
				name: 'Totale',
			}
			for(let day of this.selectedDays){
				let dayOfWeek = res.dayOfWeek.find(dow => Number(dow.dayOfWeek) === Number(day)) || {}
				objTotal[day] = dayOfWeek.total
				objCount[day] = dayOfWeek.count
			}
			objTotal.total = res.dayOfWeek.reduce((acc, curr) => acc + Number(curr.total), 0)
			objCount.total = res.dayOfWeek.reduce((acc, curr) => acc + Number(curr.count), 0)

			this.dayAndHourTotalData.push(objTotal)
			this.dayAndHourCountData.push(objCount)



			this.daysData = {}
			this.daysChartData = {}
			let weekTotal = Number(objTotal.total)

			for(let day of this.selectedDays) {
				let dayTotal = _.sumBy(res.serviceAndItemByHours[day], (row) => Number(row.total))
				let count = _.sumBy(res.serviceAndItemByHours[day], (row) => Number(row.count))

				let objTotal = {
					name: 'Tot ' + this.dayMapped[day],
					total: dayTotal,
					count: count,
					onTotal: this.calculatePercentValues(weekTotal, dayTotal) + '%',
					onDay: '100%',
					subItems: [],
					key: day + '-total'
				}

				let groupedByType = _.groupBy(res.serviceAndItemByHours[day], 'type')
					for(let type of Object.keys(groupedByType)){
						let typeData = groupedByType[type]

						let total = _.sumBy(typeData, (row) => Number(row.total))
						let objType = {
							name: this.typeMapped[type],
							total: total,
							count: _.sumBy(typeData, (row) => Number(row.count)),
							onDay: this.calculatePercentValues(dayTotal, total) + '%',
							subItems: []
						}

						let groupedByTag = _.groupBy(typeData, 'tagId')
						for(let tag of Object.keys(groupedByTag)){
							let tagData = groupedByTag[tag]

							let total = _.sumBy(tagData, (row) => Number(row.total))
							let objTag = {
								name: tagData[0].tagName,
								total: total,
								count: _.sumBy(tagData, (row) => Number(row.count)),
								onDay: this.calculatePercentValues(dayTotal, total) + '%',
							}

							objType.subItems.push(objTag)
						}

						objTotal.subItems.push(objType)
					}

				this.daysData[day] = [objTotal]
				this.daysChartData[day] = {
						labels: [],
						datasets: [
							{
								label: 'Label',
								pointBackgroundColor: 'black',
								borderWidth: 2,
								pointBorderColor: 'black',
								data: [],
								backgroundColor: this.barColors,
							}
						]
					}

				let groupedByHour = _.groupBy(res.serviceAndItemByHours[day], 'hourOfDay')
				for(let hour of Object.keys(groupedByHour)){
					let hourData = groupedByHour[hour]

					let total = _.sumBy(hourData, (row) => Number(row.total))
					let objHour = {
						name: this.hourMapped[hour],
						total: total,
						count: _.sumBy(hourData, (row) => Number(row.count)),
						onTotal: this.calculatePercentValues(weekTotal, total) + '%',
						onDay: this.calculatePercentValues(dayTotal, total) + '%',
						subItems: [],
						key: day + '-' + hour
					}

					this.daysChartData[day].labels.push(this.hourMapped[hour])
					this.daysChartData[day].datasets[0].data.push(total)

					let groupedByType = _.groupBy(hourData, 'type')
					for(let type of Object.keys(groupedByType)){
						let typeData = groupedByType[type]

						let total = _.sumBy(typeData, (row) => Number(row.total))
						let objType = {
							name: this.typeMapped[type],
							total: total,
							count: _.sumBy(typeData, (row) => Number(row.count)),
							onDay: this.calculatePercentValues(dayTotal, total) + '%',
							subItems: []
						}

						let groupedByTag = _.groupBy(typeData, 'tagId')
						for(let tag of Object.keys(groupedByTag)){
							let tagData = groupedByTag[tag]

							let total = _.sumBy(tagData, (row) => Number(row.total))
							let objTag = {
								name: tagData[0].tagName,
								total: total,
								count: _.sumBy(tagData, (row) => Number(row.count)),
								onDay: this.calculatePercentValues(dayTotal, total) + '%',
							}

							objType.subItems.push(objTag)
						}

						objHour.subItems.push(objType)
					}


					this.daysData[day].push(objHour)
				}
			}

			this.loading = false
		},
		calculatePercentValues(total, part) {
      // x : 100 = part : total
      if(!Number(total)) return 0
      total = Number(Number(total).toFixed(2))
      part = Number(Number(part).toFixed(2))
      return Number(Number( (part * 100) / total ).toFixed(2))
    },
		activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
					this.ghost = !this.ghost
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
		print() {
			let id = 'content-to-print'
			this.printLoading = true

			html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
				let a = document.createElement("a");
				a.download = "Fasce_Orarie_" + this.startDate.getDate() + "-" + this.startDate.getMonth() + ".png";
				a.href = canvas.toDataURL("image/png");
				a.click(); // MAY NOT ALWAYS WORK!
				this.printLoading = false
			}).catch((error) => {
				console.error(error)
				this.printLoading = false
			});
		}
	}
}
</script>