<template>
  <div>  
      <div class="d-flex align-center ml-3 flex-wrap">
        <RangeDateSelector
          ref="dateSelector"
          :start-date.sync="startDate"
          :end-date.sync="endDate"
          :loading="loadingClosures"
          :disabled="loadingClosures"
          :max-width="!!this.$vuetify.breakpoint.xs ? '80vw' 
            : this.$vuetify.breakpoint.sm ? '65vw': '50vw' "
        ></RangeDateSelector>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          text
          :disabled="!startDate || !endDate"
          @click="fetchClosures"
          :loading="loadingClosures"
        >Calcola</v-btn>
      </div>
      <div v-if="cashDesksClosures.length > 0" class="mt-4">
        <div class="d-flex align-center">
          <v-subheader class="text-h6 py-1">Casse</v-subheader>
          <v-btn
            icon
            @click="collapseCashDeskSection"
          >
            <v-icon
              class="collapser-icon"
              :class="{
                expanded: !collapsedCashDesk,
                collapsed: collapsedCashDesk,
              }"
            >mdi-chevron-down</v-icon>
          </v-btn>
        </div>

        <v-expand-transition>
          <div class="d-flex flex-wrap ml-2" v-if="!collapsedCashDesk">
            <CalculatorCard 
              v-for="cashDesk in cashDesksClosures" 
              :key="cashDesk.cashDeskId" 
              :object="cashDesk"
              :can-view-ghost="canViewGhost"
              :title="cashDesk.cashDeskName"
              :cashFund="cashDesk.cashDeskCashFund"
              class="ma-2"
              style="height: fit-content"
            ></CalculatorCard>
          </div>
        </v-expand-transition>
      </div>

      <div v-if="!!totalClosures" class="mt-4">
        <div class="d-flex align-center">
          <v-subheader class="text-h6 py-1">Totale</v-subheader>
          <v-btn
            icon
            @click="collapseTotalSection"
          >
            <v-icon
              class="collapser-icon"
              :class="{
                expanded: !collapsedTotal,
                collapsed: collapsedTotal,
              }"
            >mdi-chevron-down</v-icon>
          </v-btn>
        </div>

        <v-expand-transition>
          <div class="d-flex flex-wrap" v-if="!collapsedTotal">
            <CalculatorCard
              :object="totalClosures"
              :can-view-ghost="canViewGhost"
              title="Totale"
              class="ma-2"
            ></CalculatorCard>
          </div>
        </v-expand-transition>
      </div>
    </div>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import DatePicker from '@/components/common/DatePickerCommon.vue'
import CalculatorCard from '@/components/areas/payments/closures/CalculatorCard.vue'
import closuresService from "@/services/closures/closures.service.js"
import Vue from 'vue'
import { DateTime } from "luxon";

export default {
  name: "ClosureCustom",
  components: {
    FullScreenDialog,
    DatePicker,
    RangeDateSelector,
    CalculatorCard,
  },
  data: function() {
    let props = this.$router.resolve({ name: "Payments" });

    return {
      dialog: true,
      loadingClosures: false,
      routeFather: props.href,
      startDate: undefined,
      endDate: undefined,
      cashDesksClosures: [],
      totalClosures: undefined,

      ghostPermission: [],
      collapsedCashDesk: false,
      collapsedTotal: false,
    }
  },
  props: {
    bus: {
      type: Object,
      default: new Vue()
    },
    canViewGhost: {
      type: Boolean,
      default: false
    },
  },
  mounted: function() {
    if(!!this.bus) {
      this.bus.$on('reload', () => {
        if(!!this.startDate && !!this.endDate) this.fetchClosures()
      })
    }
  },
  methods: {
    fetchClosures() {
      if(!this.startDate || !this.endDate) {
        this.$delegates.snackbar("Selezionare data di inizio e fine")
        return
      }

      let startDate = DateTime.fromJSDate(this.startDate, { 
        zone: 'Europe/Rome'
      }).startOf('day').toJSDate()
      
      let endDate = DateTime.fromJSDate(this.endDate, {
        zone: 'Europe/Rome'
      }).endOf('day').toJSDate()

      this.loadingClosures = true
      closuresService.close(startDate, endDate).then((results) => {
        this.cashDesksClosures = results.cashDesks
        this.totalClosures = results.total
        this.loadingClosures = false
      })
    },
    collapseCashDeskSection() {
      this.collapsedCashDesk = !this.collapsedCashDesk
    },
    collapseTotalSection() {
      this.collapsedTotal = !this.collapsedTotal
    }
  },
  computed: {
  },
}
</script>

<style>
.collapser-icon {
  transition: all 0.2s;
}

.collapser-icon.expanded {
  transform: rotate(180deg);
}

.collapser-icon.collapsed {
  transform: rotate(0deg);
}

</style>